import * as React from "react"
import { graphql, Link } from "gatsby"
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// @ts-ignore
import logo from '../images/logo@2x.png'

const PhotoPage = ({ data }) => {

  const image = getImage(data.contentfulPhoto.photo.localFile.childImageSharp)

  return (
    <main className="text-stone-700">
      <h3 className="p-4 md:p-12 mx-auto max-w-xl"><Link to="/" className={`block h-12 overflow-hidden -indent-[1000px] bg-contain bg-no-repeat bg-center`} style={{ backgroundImage: `url(${logo})` }}>Gyvos Sienos</Link></h3>
      <div className="max-w-4xl md:px-4 mx-auto">
        {
          image ?
            <GatsbyImage alt={data.contentfulPhoto.title} imgStyle={{objectFit: 'contain', maxHeight: 880}} style={{ maxHeight: 880 }} image={image} imgClassName="md:shadow-xl" /> :
            <strong>No image</strong>
        }
      </div>
      <section className="p-4 mx-auto max-w-xl">
        <h1 className="text-2xl font-semibold mt-4 mb-2">{data.contentfulPhoto.title}</h1>
        {
          data.contentfulPhoto.author ?
            <p className="italic mb-2">
              {data.contentfulPhoto.author}
              {data.contentfulPhoto.year ? <>, {data.contentfulPhoto.year} m.</> : <></>}
            </p> :
            (data.contentfulPhoto.year ? 
              <p className="italic mb-2">{data.contentfulPhoto.year} m.</p> : 
              <></>
            )
        }
        {
          data.contentfulPhoto.description ?
            <div>{renderRichText(data.contentfulPhoto.description)}</div> : <></>
        }

        <div className="gap-4 grid grid-cols-2 sm:grid-cols-4 my-8 text-sm">
          {
            data.contentfulPhoto.contentfulid > 1 ?
              <Link className="flex flex-col items-center bg-stone-500/10 p-4 rounded-lg order-3 sm:order-1" to={`/${data.contentfulPhoto.contentfulid - 1}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6"><path fillRule="evenodd" d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clipRule="evenodd" /></svg>
                <span className="block mt-2 text-center">Ankstesnė<br />nuotrauka</span>
              </Link> :
              <div className="flex flex-col items-center text-stone-400 p-4 order-3 sm:order-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6"><path fillRule="evenodd" d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clipRule="evenodd" /></svg>
                <span className="block mt-2 text-center">Ankstesnė<br />nuotrauka</span>
              </div>
          }

          <a className="flex flex-col items-center bg-stone-500/10 p-4 rounded-lg order-1 sm:order-2" href={`http://maps.google.com/maps?q=${data.contentfulPhoto.location.lat
            },${data.contentfulPhoto.location.lon
            }`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" /><path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" /></svg>
            <span className="block mt-2 text-center">Vieta<br />žemėlapyje</span>
          </a>

          <Link className="flex flex-col items-center bg-stone-500/10 p-4 rounded-lg order-2 sm:order-3" to="/">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" /></svg>
            <span className="block mt-2 text-center">Visos<br />nuotraukos</span>
          </Link>

          {
            data.contentfulPhoto.contentfulid < 56 ?
              <Link className="flex flex-col items-center bg-stone-500/10 p-4 rounded-lg order-4 sm:order-4" to={`/${data.contentfulPhoto.contentfulid + 1}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6"><path fillRule="evenodd" d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z" clipRule="evenodd" /></svg>
                <span className="block mt-2 text-center">Kita<br />nuotrauka</span>
              </Link> :
              <div className="flex flex-col items-center text-stone-400 p-4 order-4 sm:order-4">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6"><path fillRule="evenodd" d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z" clipRule="evenodd" /></svg>
                <span className="block mt-2 text-center">Kita<br />nuotrauka</span>
              </div>
          }
        </div>
      </section>
    </main>
  )
}

export default PhotoPage

export const Head = ({ data }) => <title>{data.contentfulPhoto.title}</title>

export const query = graphql`
  query PhotoQuery($contentfulid: Int) {
    contentfulPhoto(contentfulid: {eq: $contentfulid}) {
      author
      contentfulid
      location {
        lat
        lon
      }
      description {
        raw
      }
      photo {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
      year
      title
    }
  }
`
